import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {AuthenticationService} from "./authentication.service";
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authDetail: any = [];
  providerDetail: any = [];
  loadProviderData: Subject<any> = new Subject<any>();
  doctorDetails: any = [];

  // For amplification feature in view recording
  recordingAmplifySubject = new Subject();

  // Added subject for fixing bug:
  // When doctor requests for heartbeat, and after listening to it, 
  // doctor closes the dialog, and doctor sees his own video, but his patient cant
  videoCallEndRepublish: Subject<any> = new Subject<any>();

  constructor(public authService: AuthenticationService, public apiService: ApiService, public toastrService: ToastrService,) {
    this.authDetail = this.authService.getAuthDetail()['authDetail'];
    this.getProviderDetail();
  }

  setNewAuthDetail() {
    let data = {
      emailOrPhone: this.authDetail.email,
    };
    let regData = {
      path: 'auth/auth/getLoginInfo',
      data: data,
      isAuth: false,
    };
    this.apiService.postWithoutToken(regData).subscribe((response) => {
      if (response['status']['code'] == 'OK') {
        this.authService.setNewAuthDetail(response['data']);
      }
    });
  }

  getProviderDetail() {
    let regData = {
      path: 'common/provider/detail',
      isAuth: false,
    };
    if (this.authService.isAuthenticated()) {
      return new Promise<void>((resolve) => {
        this.apiService.get(regData).subscribe((response) => {
          this.doctorDetails = JSON.parse(localStorage.getItem('doctorDetails'));
          console.log(this.doctorDetails);
          if (response['status']['code'] == 'OK') {
            this.providerDetail = response['data'];
            this.providerDetail['doctors'].map((item, index) => {
              if (item['profileImageUrl']) {
                item['isProfile'] = true;
              }
              if (!item['profileImageUrl']) {
                item['isProfile'] = false;
                if (item['firstName']) {
                  item['profileImageUrl'] = item['firstName'][0];
                }
                if (item['lastName']) {
                  item['profileImageUrl'] += item['lastName'][0];
                }
              }
            });
            console.log(this.providerDetail);
            if(this.doctorDetails ==null){
              this.doctorDetails = this.providerDetail.doctors[0];
              localStorage.removeItem('doctorDetails');
              localStorage.setItem('doctorDetails', JSON.stringify(this.doctorDetails));
              console.log('sss')
              this.loadProviderData.next();
            }else{
              console.log('ddd')
              this.loadProviderData.next();
            }
          } else {
            this.toastrService.error(response['status']['description']);
            //this.authService.logout();
          }
          resolve();
        });
      });
    }
  }
}

function forEach() {
  throw new Error('Function not implemented.');
}
